import {
  SET_ALL_SECTION,
  SET_SHARED_SURVEY_FREQUENCIES,
  SET_SHARED_SURVEY_ORGANIZATIONS,
  SET_SINGLE_SURVEY_DETAILS,
  SET_SUBMITTED_SURVEY_RESPONSES,
  SET_SURVEY_LIST,
  SET_SURVEY_SECTION_QUESTIONS,
  SET_VERSION_HISTORY,
  SET_CHANGE_PASSWORD_DIALOG,
  SET_SURVEY_SECTION_TO_QUESTIONS_MAP,
} from "constants/phase-3/survey/surveyConst";
import apiBolerplate from "utils/_helpers/apiBolerplate";

export function setSurveyList(payload) {
  return {
    type: SET_SURVEY_LIST,
    payload: payload,
  };
}
export function setSingleSurveyDetails(payload) {
  return {
    type: SET_SINGLE_SURVEY_DETAILS,
    payload: payload,
  };
}
export function setChangePasswordDialog(payload) {
  return {
    type: SET_CHANGE_PASSWORD_DIALOG,
    payload: payload,
  };
}
export function setAllSections(payload) {
  return {
    type: SET_ALL_SECTION,
    payload: payload,
  };
}

export function setSurveySectionQuestions(payload) {
  return {
    type: SET_SURVEY_SECTION_QUESTIONS,
    payload: payload,
  };
}

export function setSurveySectionToQuestionsMap(payload) {
  return {
    type: SET_SURVEY_SECTION_TO_QUESTIONS_MAP,
    payload: payload,
  };
}

export function setVersionHistory(payload) {
  return {
    type: SET_VERSION_HISTORY,
    payload: payload,
  };
}

export function setSharedSurveyOrganizations(payload) {
  return {
    type: SET_SHARED_SURVEY_ORGANIZATIONS,
    payload: payload,
  };
}

export function setSharedSurveyFrequencies(payload) {
  return {
    type: SET_SHARED_SURVEY_FREQUENCIES,
    payload: payload,
  };
}

export function setSubmittedSurveyResponses(payload) {
  return {
    type: SET_SUBMITTED_SURVEY_RESPONSES,
    payload: payload,
  };
}

export function getSurveyList(
  organization_id,
  shared_to_org_id,
  page,
  limit,
  keyword
) {
  console.log("page", page);
  console.log("limit", limit);
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey",
      bodyData: { organization_id, shared_to_org_id, page, limit, keyword },
      hideLoader: false,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSurveyList(data?.result));
      },
    });
  };
}
export function createSurvey(
  details,
  organization_id,
  shared_to_org_id,
  page,
  limit
) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/create",
      bodyData: { ...details },
      hideLoader: false,
      hideMsg: false,
      callback: () => {
        dispatch(getSurveyList(organization_id, shared_to_org_id, page, limit));
      },
    });
  };
}

export function getSurveyDetails(survey_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/get_survey_details",
      bodyData: {
        survey_id,
      },
      hideLoader: false,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSingleSurveyDetails(data?.result));
      },
    });
  };
}

export function updateSurvey(details) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/update",
      bodyData: { ...details },
      hideLoader: true,
      hideMsg: false,
      callback: () => {
        dispatch(getSurveyDetails(details.survey_id));
      },
    });
  };
}

export function getAllSections(details) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_sections/get_all_sections",
      bodyData: {
        ...details,
      },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setAllSections(data?.result));
      },
    });
  };
}

export function createSurveySection(details, getAllSections) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_sections/add_section",
      bodyData: {
        ...details,
      },
      hideLoader: true,
      hideMsg: false,
      callback: () => {
        dispatch(
          getAllSections({
            survey_id: details?.survey_id,
            version: details?.version,
          })
        );
      },
    });
  };
}

export function updateSurveySection(details, updatedSectionList) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_sections/update_section",
      bodyData: { ...details },
      hideLoader: true,
      hideMsg: false,
      callback: () => {
        dispatch(setAllSections(updatedSectionList));
      },
    });
  };
}

export function getSurveySectionQuestions(section_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_questions/get_all_questions",
      bodyData: { section_id },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSurveySectionQuestions(data?.result));
        dispatch(
          setSurveySectionToQuestionsMap({ section_id, data: data?.result })
        );
      },
    });
  };
}

export function getSelectedSurveySectionQuestions(section_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_questions/get_all_questions",
      bodyData: { section_id },
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        dispatch(
          setSurveySectionToQuestionsMap({ section_id, data: data?.result })
        );
      },
    });
  };
}

export function getVersionHistory(survey_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/get_version_history",
      bodyData: { survey_id },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setVersionHistory(data?.result));
      },
    });
  };
}

export function publishSurvey(details) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/publish_survey",
      bodyData: { ...details },
      hideLoader: true,
      hideMsg: false,
      callback: () => {},
    });
  };
}

export function addQuestion(details) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_questions/add_question",
      bodyData: { ...details },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(getSurveySectionQuestions(details?.section_id));
      },
    });
  };
}

export function updateSurveyQuestion(details, sectionId) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_questions/update_question",
      bodyData: { ...details },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(getSurveySectionQuestions(sectionId));
      },
    });
  };
}

// export function shareSupplierSurvey(details, organization_id) {
//   return async (dispatch) => {
//     await apiBolerplate({
//       url: "/survey/share_survey",
//       bodyData: { ...details },
//       hideLoader: true,
//       hideMsg: false,
//       callback: (data) => {
//         dispatch(getSurveyList(organization_id, details?.shared_to_org_id));
//       },
//     });
//   };
// }

export function shareSupplierSurvey(details, organization_id) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      apiBolerplate({
        url: "/survey/share_survey",
        bodyData: { ...details },
        hideLoader: true,
        hideMsg: false,
        callback: (data) => {
          dispatch(getSurveyList(organization_id, details?.shared_to_org_id));
          resolve(data); // Resolve promise when API call succeeds
        },
        errorCallback: (error) => {
          reject(error); // Reject promise on failure
        },
      });
    });
  };
}

export function getSharedSurveyOrganizations(survey_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/get_shared_survey_organizations",
      bodyData: { survey_id },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSharedSurveyOrganizations(data?.result));
      },
    });
  };
}

export function getSharedSurveyFrequencies(survey_id, shared_to_org_id) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey/get_shared_survey_frequencies",
      bodyData: { survey_id, shared_to_org_id },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSharedSurveyFrequencies(data?.result));
      },
    });
  };
}

export function getSubmittedSurveyResponses(
  survey_id,
  shared_to_org_id,
  frequency_id
) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_questions/get_submitted_survey_responses",
      bodyData: { survey_id, shared_to_org_id, frequency_id },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(setSubmittedSurveyResponses(data?.result));
      },
    });
  };
}

export function addConditionalLogic(
  section_id,
  condition,
  singleSurveyDetails
) {
  return async (dispatch) => {
    await apiBolerplate({
      url: "/survey_sections/add_conditional_logic",
      bodyData: { section_id, condition },
      hideLoader: true,
      hideMsg: false,
      callback: (data) => {
        dispatch(
          getAllSections({
            survey_id: singleSurveyDetails._id,
            version: singleSurveyDetails.latestVersion,
          })
        );
      },
    });
  };
}
