import {
  SET_SINGLE_SURVEY_DETAILS,
  SET_SURVEY_LIST,
  SET_ALL_SECTION,
  SET_SURVEY_SECTION_QUESTIONS,
  SET_VERSION_HISTORY,
  SET_SHARED_SURVEY_ORGANIZATIONS,
  SET_SHARED_SURVEY_FREQUENCIES,
  SET_SUBMITTED_SURVEY_RESPONSES,
  SET_CHANGE_PASSWORD_DIALOG,
  SET_SURVEY_SECTION_TO_QUESTIONS_MAP,
} from "constants/phase-3/survey/surveyConst";

const initial_state = {
  surveyList: [],
  singleSurveyDetails: null,
  allSections: [],
  surveySectionQuestions: [],
  surveySectionToQuestionsMap: {},
  versionHistory: [],
  sharedSurveyOrganizations: [],
  sharedSurveyFrequencies: [],
  submittedSurveyResponses: [],
  changePasswordDialog: false,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "RESET_STATE":
      return (state = {
        ...state,
        ...initial_state,
      });
    case SET_SURVEY_LIST:
      return (state = {
        ...state,
        surveyList: action.payload,
      });

    case SET_SINGLE_SURVEY_DETAILS:
      return (state = {
        ...state,
        singleSurveyDetails: action.payload,
      });

    case SET_ALL_SECTION:
      return (state = {
        ...state,
        allSections: action.payload,
      });

    case SET_SURVEY_SECTION_QUESTIONS:
      return (state = {
        ...state,
        surveySectionQuestions: action.payload,
      });

    case SET_SURVEY_SECTION_TO_QUESTIONS_MAP:
      return (state = {
        ...state,
        surveySectionToQuestionsMap: {
          ...state.surveySectionToQuestionsMap,
          [action.payload?.section_id]: action.payload?.data,
        },
      });

    case SET_VERSION_HISTORY:
      return (state = {
        ...state,
        versionHistory: action.payload,
      });

    case SET_SHARED_SURVEY_ORGANIZATIONS:
      return (state = {
        ...state,
        sharedSurveyOrganizations: action.payload,
      });

    case SET_SHARED_SURVEY_FREQUENCIES:
      return (state = {
        ...state,
        sharedSurveyFrequencies: action.payload,
      });

    case SET_SUBMITTED_SURVEY_RESPONSES:
      return (state = {
        ...state,
        submittedSurveyResponses: action.payload,
      });
    case SET_CHANGE_PASSWORD_DIALOG:
      return (state = {
        ...state,
        changePasswordDialog: action.payload,
      });

    default:
      return state;
  }
}
