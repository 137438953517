export const SET_SUPPLIER_SHARED_SURVEYS = "SET_SUPPLIER_SHARED_SURVEYS";
export const SET_SUPPLIER_ADMIN_STAKEHOLDERS =
  "SET_SUPPLIER_ADMIN_STAKEHOLDERS";
export const SET_SINGLE_SHARED_SURVEY = "SET_SINGLE_SHARED_SURVEY";
export const SET_SUPPLIER_ASSIGNED_SURVEYS = "SET_SUPPLIER_ASSIGNED_SURVEYS";
export const SET_SUPPLIER_SINGLE_TASK_DETAILS =
  "SET_SUPPLIER_SINGLE_TASK_DETAILS";
export const SET_SUPPLIER_SINGLE_SURVEY_DETAILS =
  "SET_SUPPLIER_SINGLE_SURVEY_DETAILS";
export const SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_CONTRIBUTOR =
  "SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_CONTRIBUTOR";
export const SET_EXPLORE_QUESTION_DETAILS = "SET_EXPLORE_QUESTION_DETAILS";
export const SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_APPROVAL =
  "SET_SUPPLIER_ASSIGNED_QUESTIONS_FOR_APPROVAL";
export const SET_SHARED_APPROVED_QUESTIONS = "SET_SHARED_APPROVED_QUESTIONS";
