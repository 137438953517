export const SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS =
  "SET_ESG_COMPLIANCE_ASSESSMENT_DETAILS";
export const SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS =
  "SET_ORIGINAL_ESG_COMPLIANCE_ASSESSMENT_DETAILS";
export const SET_SELECTED_COMPLIANCE_ROW_DATA =
  "SET_SELECTED_COMPLIANCE_ROW_DATA";
export const SET_ESG_COMPLIANCE_STRUCTURED_REPORT_DATA =
  "SET_ESG_COMPLIANCE_STRUCTURED_REPORT_DATA";
export const SET_ESG_COMPLIANCE_FILTERED_REPORT_DATA =
  "SET_ESG_COMPLIANCE_FILTERED_REPORT_DATA";
