import { combineReducers } from "redux";
import login from "./login/login-reducer";
import snackbar from "./snackbar/snackbar_reducer";
import loader from "./loader/loader_reducer";
import businessUnit from "./businessUnit/businessUnitReducer";
import frameworks from "./frameworks/frameworkReducer";
import topic from "./topics/topicReducer";
import basicInfo from "./basicinfoReducer/basicinfoReducer";
import materialityAssessmentOld from "./metarialityAssessment/metarialityAssessmentReducer";
import esgStrategy from "./esgStrategy/esgStrategyReducer";
import assesmentApproval from "./assesmentApproval/assesmentApprovalReducer";
import fieldData from "./fieldData/fieldDataReducer";
import sustainabilityReport from "./sustainabilityReport/sustainabilityReportReducer";
import surveyListing from "./surveyListing/surveyListingReducer";
import reportlisting from "./reportListing/reportListingReducer";
import generalSettings from "./generalSettings/generalSettingsReducer";
import comments from "./comments/commentsReducer";
import { loadingBarReducer } from "react-redux-loading-bar";
import maturityMatrix from "./maturityMatrix/maturityMatrixReducer";
import dataHub from "./datahub/datahubReducer";
import layout from "./layout/layoutReducer";

import user from "./phase-3/auth/userReducer";
import common from "./phase-3/common/commonReducer";
import orgDetails from "./phase-3/orgDetails/orgDetailsReducer";
import materialityAssessment from "./phase-3/materialityAssessment/matAssessmentReducer";
import supplierAssessment from "./phase-3/supplierAssessment/supplierAssessmentReducer";
import surveyReducer from "./phase-3/survey/surveyReducer";
import supplierAdminReducer from "./phase-3/supplierAdmin/supplierAdminReducer";
import esgStrategyPlanning from "./phase-3/esgStrategyPlanning/esgStrategyPlanningReducer";
import esgMaturity from "./phase-3/esgMaturity/esgMaturityReducer";
import frameWorV3 from "./phase-3/frameWork/frameWorkReducer";

// task
import materialityTask from "./phase-3/task/materialityTaskReducer/materialityTaskReducer";
import maturityTask from "./phase-3/task/maturityTaskReducer/maturityTaskReducer";
import esgStrategyTask from "./phase-3/task/esgStrategyPlanning/esgStrategyPlanningTaskReducer";
import esgComplianceTask from "./phase-3/task/esgCompliance/esgComplianceTaskReducer";

import gptWise from "./phase-3/gptWise/gptWiseReducer";
import chatTimers from "./phase-3/chatTimers/chatTimersReducer";
import escCompliance from "./phase-3/esgCompliance/esgComplianceReducer";

import thread from "./phase-3/thread/threadReducer";
import dataset from "./phase-3/datahub/datasetReducer";

// test
import test from "./phase-3/test/testReducer";

export default combineReducers({
  user,
  common,
  login,
  snackbar,
  loader,
  businessUnit,
  frameworks,
  topic,
  basicInfo,
  materialityAssessmentOld,
  esgStrategy,
  assesmentApproval,
  fieldData,
  sustainabilityReport,
  surveyListing,
  reportlisting,
  generalSettings,
  comments,
  loadingBar: loadingBarReducer,
  maturityMatrix,
  dataHub,
  layout,
  orgDetails,
  materialityAssessment,
  esgStrategyPlanning,
  esgMaturity,
  materialityTask,
  maturityTask,
  esgStrategyTask,
  esgComplianceTask,
  gptWise,
  chatTimers,
  frameWorV3,
  escCompliance,
  thread,
  test,
  dataset,
  supplierAssessment,
  surveyReducer,
  supplierAdminReducer,
});
