export function emailValidation(email) {
    const RE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return RE.test(String(email).toLowerCase());
  }
  
  export function phoneNumberValidation(phone_no) {
    const RE = /^[6-9]\d{9}$/;
    return RE.test(String(phone_no));
  }
  export function passwordValidation(password) {
    const RE =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/;
    return RE.test(String(password));
  }
  