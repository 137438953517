export const SET_ENTITY_LIST = "SET_ENTITY_LIST";
export const SET_UNITS_LIST = "SET_UNITS_LIST";
export const SET_ASSETS_LIST = "SET_ASSETS_LIST";
export const SET_STAKEHOLDERS_LIST = "SET_STAKEHOLDERS_LIST";
export const SET_SUPPLIERS_LIST = "SET_SUPPLIERS_LIST";
export const SET_PRODUCTNSERVICE_LIST = "SET_PRODUCTNSERVICE_LIST";
export const SET_PARENT_ENTITY = "SET_PARENT_ENTITY";
export const SET_ORGANISATION_STRUCTURE = "SET_ORGANISATION_STRUCTURE";

export const SET_USER_ROLES = "SET_USER_ROLES";
