export const SET_SURVEY_LIST = "SET_SURVEY_LIST";
export const SET_SINGLE_SURVEY_DETAILS = "SET_SINGLE_SURVEY_DETAILS";
export const SET_ALL_SECTION = "SET_ALL_SECTION";
export const SET_SURVEY_SECTION_QUESTIONS = "SET_SURVEY_SECTION_QUESTIONS";
export const SET_SURVEY_SECTION_TO_QUESTIONS_MAP =
  "SET_SURVEY_SECTION_TO_QUESTIONS_MAP";
export const SET_VERSION_HISTORY = "SET_VERSION_HISTORY";
export const SET_SHARED_SURVEY_ORGANIZATIONS =
  "SET_SHARED_SURVEY_ORGANIZATIONS";
export const SET_CHANGE_PASSWORD_DIALOG = "SET_CHANGE_PASSWORD_DIALOG";
export const SET_SHARED_SURVEY_FREQUENCIES = "SET_SHARED_SURVEY_FREQUENCIES";
export const SET_SUBMITTED_SURVEY_RESPONSES = "SET_SUBMITTED_SURVEY_RESPONSES";
