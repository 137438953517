import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import useFormValidation from "customHooks/useFormValidation";
// redux
import { useDispatch, useSelector } from "react-redux";
// icons
import { LuEye, LuEyeOff } from "react-icons/lu";
import { userLogin } from "actions/phase-3/auth/authAction";
import { Link } from "react-router-dom";
import ForgotPasswordDialog from "../ForgotPasswordDialog";
const LoginForm = () => {
  const dispatch = useDispatch();

  // states
  const [showPassword, setShowPassword] = useState(false);
  const [forgotDialog, setForgotDialog] = useState(false);

  const [resetEmail, setResetEmail] = useState("");
  const initialState = {
    email: "",
    password: "",
  };

  const validationRules = {
    email: { type: "email", required: true },
    password: { type: "password", required: true, minLength: 8 },
  };

  const {
    formData,
    errors,
    handleChange,
    handleSubmit,
    hasErrors,
    setFormData,
  } = useFormValidation(initialState, validationRules);

  const disableSubmit = !formData.email || !formData.password || hasErrors;

  const handleForgotDialog = () => {
    setResetEmail(formData.email);

    setForgotDialog(true);
  };
  const navigateToTnc = () => {
    window.open("/tnc");
  };
  const navigateToPrivacyPolicy = () => {
    window.open("/privacy_policy");
  };
  const onSubmit = () => {
    dispatch(userLogin(formData.email, formData.password));
    // setChangeDialog(true)
  };

  // useEffect(() => {
  //   if (localStorage.getItem("isLoggedIn") === "false") {
  //     setFormData({ email: "", password: "" });
  //   }
  // }, [localStorage.getItem("isLoggedIn")]);

  // console.log(localStorage.getItem("isLoggedIn"));

  return (
    <Box>
      <Stack spacing={2}>
        {/* Email */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Email {requiredField(Boolean(errors.email) || !formData.email)}
          </Typography>
          <TextField
            name="email"
            type="email"
            placeholder="Enter Email"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            required
            fullWidth
          />
        </Box>

        {/* Password */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Password{" "}
            {requiredField(Boolean(errors.password) || !formData.password)}
          </Typography>
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{ color: "primary.main" }}
                  >
                    {showPassword ? <LuEye /> : <LuEyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Actions */}
        <Stack spacing={2} textAlign={"center"}>
          <Button
            variant="text"
            size="small"
            sx={{
              textAlign: "left",
              width: "fit-content",
              padding: 0,
            }}
            onClick={handleForgotDialog}
          >
            Forgot Password
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={disableSubmit}
            fullWidth
            onClick={onSubmit}
          >
            Log in
          </Button>
          <Box>
            <Typography variant="body1" color={"action.disabled"}>
              Don't have an account?{" "}
              <Button
                component={Link}
                to="/"
                variant="text"
                sx={{ color: "primary.light", p: 0 }}
              >
                Signup here
              </Button>
            </Typography>
            <Typography variant="body1" color={"action.disabled"}>
              <Button
                variant="text"
                sx={{ color: "primary.light", p: 0 }}
                onClick={navigateToTnc}
              >
                Terms of services
              </Button>{" "}
              and{" "}
              <Button
                variant="text"
                sx={{ color: "primary.light", p: 0 }}
                onClick={navigateToPrivacyPolicy}
              >
                Privacy Policy
              </Button>
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <ForgotPasswordDialog
        open={forgotDialog}
        onClose={() => setForgotDialog(false)}
        initialEmail={resetEmail}
        setForgotDialog={setForgotDialog}
      />
    </Box>
  );
};

export default LoginForm;
