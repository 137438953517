import { Button, Card, CardContent, Grid, InputAdornment, Typography } from '@mui/material';
import { passwordValidation } from 'actions/phase-3/regexValidation/regexValidation';
import React from 'react'
import { useLocation } from 'react-router-dom';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"; 
import Textfield from 'components/UI/phase-3/textfield/textfield';
import { makeStyles } from '@mui/styles';
import { resetPassword } from 'actions/phase-3/auth/authAction';
import { useDispatch } from 'react-redux';
export const resetPasswordStyle = makeStyles((theme) => ({
  mainGrid: {
    height: "100vh",
    backgroundColor: "#21134A",
  },
  card: {
    borderRadius: 10,
  },
  cardTypography: {
    margin: "10px 0px 20px 0px",
  },
  cardTextField: {
    width: "100%",
  },
  button: {
    margin: "40px 0px 20px 0px",
  },
}));
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
function ResetPassword() {
    const classes = resetPasswordStyle();
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch=useDispatch()
  
   
    let resetcode = useQuery().get("resetcode");
    console.log("passcode",resetcode)
 
    const handleResetPassword= ()=>{
            dispatch(resetPassword(resetcode,password,confirmPassword))
    }
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.mainGrid}
        >
          <Grid item xs={8} md={5} lg={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" className={classes.cardTypography}>
                  RESET PASSWORD
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Please enter the password below and click on reset to change the
                  password
                </Typography>
                <Textfield
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  label="Password"
                  className={classes.cardTextField}
                  value={password}
                  error={password !== "" && !passwordValidation(password)}
                  helperText="At least 8 character, 1 uppercase, 1 lowercase, 1 number and 1 symboll."
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon fontSize="small" color="disabled" />
                        ) : (
                          <VisibilityOffIcon fontSize="small" color="disabled" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Textfield
                  id="Confirm_password"
                  name="Confirm_password"
                  type={showPassword ? "text" : "password"}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  label="Confirm Password"
                  className={classes.cardTextField}
                  value={confirmPassword}
                  error={confirmPassword !== "" && confirmPassword !== password}
                  helperText={
                    confirmPassword !== "" &&
                    confirmPassword !== password &&
                    "Password and confirm password doesn't match."
                  }
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={
                    password !== confirmPassword ||
                    (password === "" && confirmPassword === "") ||
                    !passwordValidation(password)
                      ? true
                      : false
                  }
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
  export default React.memo(ResetPassword);
  